import React, { useState } from "react";
import {
  Calculator,
  CircleDollarSign,
  Car,
  ShoppingBag,
  Smartphone,
  Watch,
  Wallet,
} from "lucide-react";
import LoginSession from "./LoginSession";
import { getAnalytics, logEvent } from "firebase/analytics";

const QuestionVanity = () => {
  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");
  const [inputs, setInputs] = useState({
    salary: "",
    itemPrice: "",
    fixedExpenses: "",
    deviceLifespan: 24,
  });
  const [showResults, setShowResults] = useState(false);
  const analytics = getAnalytics();

  // 차량 데이터
  const carItems = [
    {
      name: "아반떼",
      price: 23000000,
      image: "/vanityImage/carImage/car_avante.jpg",
    },
    {
      name: "쏘나타",
      price: 32000000,
      image: "/vanityImage/carImage/car_sonata.jpg",
    },
    {
      name: "G80",
      price: 55000000,
      image: "/vanityImage/carImage/car_gv80.png",
    },
    {
      name: "BMW5시리즈",
      price: 75000000,
      image: "/vanityImage/carImage/car_bmw.png",
    },
    {
      name: "포르쉐 992",
      price: 185000000,
      image: "/vanityImage/carImage/car_po.png",
    },
    {
      name: "람보르기니 우루스",
      price: 405000000,
      image: "/vanityImage/carImage/car_rambo.jpg",
    },
  ];

  //핸드백 데이터
  const bagItems = [
    {
      name: "코치 토트백",
      price: 500000,
      image: "/vanityImage/bagImage/bag_coach.jpeg",
    },
    {
      name: "구찌 마몬트",
      price: 2500000,
      image: "/vanityImage/bagImage/bag_gucci.jpeg",
    },
    {
      name: "루이비통 네버풀",
      price: 5500000,
      image: "/vanityImage/bagImage/bag_louisvuitton.jpeg",
    },
    {
      name: "샤넬 플랩백",
      price: 13000000,
      image: "/vanityImage/bagImage/bag_chanel.webp",
    },
    {
      name: "에르메스 버킨",
      price: 21000000,
      image: "/vanityImage/bagImage/bag_hermes.webp",
    },
  ];

  // 허세지수 카테고리 정의
  const categories = [
    {
      id: "car",
      title: "자동차 허세지수",
      icon: <Car className="w-5 h-5" />,
      baseColor: "bg-blue-500",
      hoverColor: "hover:bg-blue-600",
      textColor: "text-blue-500",
    },
    {
      id: "bag",
      title: "핸드백 허세지수",
      icon: <ShoppingBag className="w-5 h-5" />,
      baseColor: "bg-pink-500",
      hoverColor: "hover:bg-pink-600",
      textColor: "text-pink-500",
    },
    {
      id: "device",
      title: "스마트기기 허세지수",
      icon: <Smartphone className="w-5 h-5" />,
      baseColor: "bg-purple-500",
      hoverColor: "hover:bg-purple-600",
      textColor: "text-purple-500",
    },
    {
      id: "watch",
      title: "명품시계 허세지수",
      icon: <Watch className="w-5 h-5" />,
      baseColor: "bg-amber-500",
      hoverColor: "hover:bg-amber-600",
      textColor: "text-amber-500",
    },
    {
      id: "expense",
      title: "지출 허세지수",
      icon: <Wallet className="w-5 h-5" />,
      baseColor: "bg-green-500",
      hoverColor: "hover:bg-green-600",
      textColor: "text-green-500",
    },
  ];

  // 컨설팅 메시지 생성
  const getConsulting = (category, level) => {
    var levelEng = "";
    if (level === "저허세" || level === "정상") {
      levelEng = "low";
    }
    if (level === "과한허세" || level === "고도허세") {
      levelEng = "medium";
    }
    if (level === "허언증 작렬") {
      levelEng = "high";
    }

    const messages = {
      high: {
        car: "차량 구매 비용이 과다합니다. 소득의 50% 이하로 조정하거나, 중고차를 고려해보세요.",
        bag: "핸드백 구매 비용이 높습니다. 연봉의 5% 이내로 조정을 추천드립니다.",
        device:
          "기기 가격이 높습니다. 실사용 기간을 고려해 더 저렴한 모델을 검토해보세요.",
        watch:
          "시계 구매 비용이 과다합니다. 연봉의 30% 이내로 조정을 추천드립니다.",
        expense:
          "사치품 지출이 높습니다. 가처분 소득의 30% 이내로 조정해보세요.",
      },
      medium: {
        car: "적절한 차량 선택입니다. 유지비용도 고려하여 계획을 세우세요.",
        bag: "적정 수준의 구매입니다. 구매 전 2-3달 정도 고민하는 것을 추천드립니다.",
        device: "적정 수준의 구매입니다. 케이스 등 보호용품 구매도 고려하세요.",
        watch: "적절한 선택입니다. 보험과 관리 비용도 고려하세요.",
        expense: "균형잡힌 지출 패턴입니다. 계획적인 소비를 유지하세요.",
      },
      low: {
        car: "건전한 소비 패턴입니다. 남은 자금으로 투자나 저축을 고려해보세요.",
        bag: "합리적인 소비를 하고 계십니다. 품질과 실용성을 고려한 선택이 돋보입니다.",
        device: "실용적인 선택입니다. 할부나 약정을 잘 활용하고 계시네요.",
        watch:
          "현명한 소비를 하고 계십니다. 시계는 장기 투자 관점으로도 좋습니다.",
        expense:
          "건전한 소비 생활을 하고 계십니다. 저축과 투자도 함께 고려해보세요.",
      },
    };

    if (messages[levelEng] && messages[levelEng][category]) {
      return messages[levelEng][category];
    } else {
      return "적절한 정보를 찾을 수 없습니다. 다시 입력해주세요.";
    }
  };

  // 입력 처리
  const handleInputChange = (field, value) => {
    setInputs((prev) => ({
      ...prev,
      [field]: formatInput(value),
    }));
  };

  const formatInput = (value) => {
    const numbers = value.replace(/[^\d]/g, "");
    return numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // 입력값 포맷팅
  const formatNumber = (value) => {
    const formatNumbers = String(value).replace(/[^\d]/g, "");
    return formatNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (value) => {
    return value ? value.replace(/,/g, "") : "0";
  };

  // 차량, 가방 아이템 허세지수 계산 => 차량은 6개월 급여, 가방은 연봉의 10% 기준
  const calculateCarBagVanityIndex = (price, category) => {
    if (category === "car") {
      const sixMonthSalary = parseFloat(removeCommas(inputs.salary)) * 6;
      return (price / sixMonthSalary).toFixed(2);
    } else if (category === "bag") {
      const yearMonthSalary =
        parseFloat(removeCommas(inputs.salary)) * 12 * 0.1;
      return (price / yearMonthSalary).toFixed(2);
    }
  };

  // 정해진 데이터 차량, 가방 허세 레벨 판정
  const getCarBagVanityLevel = (index) => {
    const numIndex = parseFloat(index);
    if (numIndex <= 1) return { level: "저허세", color: "text-green-600" };
    if (numIndex <= 1.5) return { level: "정상", color: "text-blue-600" };
    if (numIndex <= 2) return { level: "과한허세", color: "text-yellow-600" };
    if (numIndex <= 2.5) return { level: "고도허세", color: "text-orange-600" };
    return { level: "허언증 작렬", color: "text-red-600" };
  };

  // 허세 레벨 계산
  const calculateVanityIndex = () => {
    const salary = parseFloat(removeCommas(inputs.salary)) || 0;
    const price = parseFloat(removeCommas(inputs.itemPrice)) || 0;
    const fixed = parseFloat(removeCommas(inputs.fixedExpenses)) || 0;

    if (salary <= 0) {
      setShowResults(false);
      return alert("월급은 0보다 큰 수를 입력해주세요");
    }
    logEvent(analytics, "bluff_mass_index_category", {
      content_type: "category",
      content_id: category,
    });
    switch (category) {
      case "car":
        // 6개월 급여
        return (price / (salary * 6)).toFixed(2);
      case "bag":
      case "device": {
        const disposableIncome = salary - fixed;
        // 기본 24개월
        const months = parseInt(inputs.deviceLifespan);
        if (disposableIncome < 0) {
          return "Dangerous";
        }
        return (price / (disposableIncome * months)).toFixed(2);
      }
      case "watch":
        return (price / (salary * 12)).toFixed(2);
      case "expense": {
        const disposableIncome = salary - fixed;

        if (disposableIncome < 0) {
          return "Dangerous";
        }
        return (price / disposableIncome).toFixed(2);
      }
      default:
        return 0;
    }
  };

  // 허세 레벨 판정 결과
  const getVanityLevel = (index) => {
    if (index < 0) {
      return { level: "허언증 작렬", color: "text-red-600" };
    }
    const num = parseFloat(index);
    if (num <= 1) return { level: "저허세", color: "text-green-600" };
    if (num <= 1.5) return { level: "정상", color: "text-blue-600" };
    if (num <= 2) return { level: "과한허세", color: "text-yellow-600" };
    if (num <= 2.5) return { level: "고도허세", color: "text-orange-600" };
    return { level: "허언증 작렬", color: "text-red-600" };
  };

  return (
    <div className="container mx-auto p-4">
      {/* Card */}
      <div className="max-w-4xl mx-auto p-6 ">
        {/* CardHeader */}
        <div className="max-2w-xl mx-auto">
          {/* CardTitle */}
          <div className="text-2xl font-bold text-center flex items-center justify-center gap-2 mb-4">
            <Calculator className="w-6 h-6" />
            종합 허세지수 계산기
            <CircleDollarSign className="w-6 h-6" />
          </div>
        </div>
        {/* CardContent */}
        <div className="w-full max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-xl">
          <div className="space-y-6">
            {/* 성별 선택 */}
            <div className="text-center mb-6">
              <h2 className="text-xl font-bold mb-4">성별 선택</h2>
              <div className="flex gap-4 justify-center">
                <button
                  onClick={() => setGender("male")}
                  className={`flex-1 max-w-[200px] p-4 rounded-lg flex items-center justify-center gap-2
                                ${
                                  gender === "male"
                                    ? "bg-blue-500 text-white"
                                    : "bg-gray-100"
                                }
                                hover:bg-blue-600 transition-all`}
                >
                  <Car className="w-5 h-5" />
                  남성
                </button>
                <button
                  onClick={() => setGender("female")}
                  className={`flex-1 max-w-[200px] p-4 rounded-lg flex items-center justify-center gap-2
                                ${
                                  gender === "female"
                                    ? "bg-pink-500 text-white"
                                    : "bg-gray-100"
                                }
                                hover:bg-pink-600 transition-all`}
                >
                  <ShoppingBag className="w-5 h-5" />
                  여성
                </button>
              </div>
            </div>
            {/* 카테고리 선택 */}
            {gender && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {categories.map((cat) => (
                  <button
                    key={cat.id}
                    onClick={() => {
                      setCategory(cat.id);
                      setShowResults(false);
                      setInputs((prev) => ({ ...prev, itemPrice: "" }));
                    }}
                    className={`p-4 rounded-lg flex items-center justify-center gap-2 transition-all
                                  ${
                                    category === cat.id
                                      ? cat.baseColor + " text-white"
                                      : "bg-gray-100"
                                  }
                                  ${cat.hoverColor}`}
                  >
                    {cat.icon}
                    <span>{cat.title}</span>
                  </button>
                ))}
              </div>
            )}
            {/* 입력 필드 */}
            {category && (
              <div className="space-y-4 animate-fade-in">
                <div>
                  <label className="block text-sm font-medium mb-2">
                    월급 (원)
                  </label>
                  <input
                    type="text"
                    value={inputs.salary}
                    onChange={(e) =>
                      handleInputChange("salary", e.target.value)
                    }
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                    placeholder="월급을 입력하세요"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-2">
                    {category === "expense"
                      ? "월 사치품 지출액 (원)"
                      : "구매 가격 (원)"}
                  </label>
                  <input
                    type="text"
                    value={inputs.itemPrice}
                    onChange={(e) =>
                      handleInputChange("itemPrice", e.target.value)
                    }
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                    placeholder="가격을 입력하세요"
                  />
                </div>

                {(category === "device" || category === "expense") && (
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      고정 지출 (원)
                    </label>
                    <input
                      type="text"
                      value={inputs.fixedExpenses}
                      onChange={(e) =>
                        handleInputChange("fixedExpenses", e.target.value)
                      }
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                      placeholder="월 고정 지출액 (주거비, 생활비 등)"
                    />
                  </div>
                )}

                {category === "device" && (
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      예상 사용 기간
                    </label>
                    <select
                      value={inputs.deviceLifespan}
                      onChange={(e) =>
                        handleInputChange("deviceLifespan", e.target.value)
                      }
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="24">스마트폰 (24개월)</option>
                      <option value="36">노트북 (36개월)</option>
                      <option value="48">태블릿 (48개월)</option>
                    </select>
                  </div>
                )}

                <button
                  onClick={() => setShowResults(true)}
                  className="w-full p-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg
                                       font-medium hover:from-blue-600 hover:to-purple-600 transition-all duration-300
                                       transform hover:scale-105"
                >
                  허세지수 계산하기
                </button>
              </div>
            )}
            {/* 결과 표시 */}
            {showResults && (
              <div className="mt-6 p-6 bg-gray-50 rounded-lg animate-fade-in">
                {/* 해당 부분을 useEffect 를 사용해서 함수를 제외하고 함수가 있는경우 해당 return */}
                {(() => {
                  const index = calculateVanityIndex();
                  const { level, color } = getVanityLevel(index);
                  const consulting = getConsulting(category, level);
                  logEvent(analytics, "bluff_mass_index_result", {
                    content_type: "bluff_mass_index_result",
                    content_id: category,
                    result: level,
                    result_color: color,
                  });
                  return (
                    <div className="space-y-4">
                      <div className={`text-3xl font-bold ${color}`}>
                        {level}
                      </div>
                      <div className="text-xl">허세지수: {index}</div>
                      <div className="mt-4 p-4 bg-white rounded-lg">
                        <h3 className="font-medium mb-2">💡 맞춤 컨설팅</h3>
                        <p className="text-gray-600">{consulting}</p>
                      </div>
                    </div>
                  );
                })()}
              </div>
            )}
            {/* 차량 결과 표시 */}
            {showResults && category === "car" && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                {carItems.map((carItem, match) => {
                  const carBagIndex = calculateCarBagVanityIndex(
                    carItem.price,
                    category
                  );
                  const carBagLevel = getCarBagVanityLevel(carBagIndex);
                  const isRecommended = parseFloat(carBagIndex) <= 1.5;
                  return (
                    <div
                      key={match}
                      className={`p-4 rounded-lg bg-gray-50 transition-all duration-500 transform hover:scale-105 ${
                        isRecommended
                          ? "animate-pulse ring-2 ring-green-400"
                          : ""
                      }`}
                    >
                      <img
                        src={carItem.image}
                        alt={carItem.name}
                        className="w-full h-48 object-cover rounded mb-3"
                      />
                      <div className="space-y-2">
                        <div className="font-medium text-lg">
                          {carItem.name}
                        </div>
                        <div className="text-gray-600">
                          가격: {formatNumber(carItem.price)}원
                        </div>
                        <div
                          className={`${carBagLevel.color} text-lg font-bold animate-bounce`}
                        >
                          허세지수: {carBagIndex}
                        </div>
                        <div className={`${carBagLevel.color} font-medium`}>
                          {carBagLevel.level}
                        </div>
                        {isRecommended && (
                          <div className="text-green-500 text-sm mt-2 animate-pulse">
                            ✨ 추천 상품
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* 가방 결과 표시 */}
            {showResults && category === "bag" && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                {bagItems.map((bagItem, match) => {
                  const carBagIndex = calculateCarBagVanityIndex(
                    bagItem.price,
                    category
                  );
                  const carBagLevel = getCarBagVanityLevel(carBagIndex);
                  const isRecommended = 1.5;
                  return (
                    <div
                      key={match}
                      className={`p-4 rounded-lg bg-gray-50 transition-all duration-500 transform hover:scale-105 ${
                        isRecommended
                          ? "animate-pulse ring-2 ring-green-400"
                          : ""
                      }`}
                    >
                      <img
                        src={bagItem.image}
                        alt={bagItem.name}
                        className="w-full h-48 object-cover rounded mb-3"
                      />
                      <div className="space-y-2">
                        <div className="font-medium text-lg">
                          {bagItem.name}
                        </div>
                        <div className="text-gray-600">
                          가격: {formatNumber(bagItem.price)}원
                        </div>
                        <div
                          className={`${carBagLevel.color} text-lg font-bold animate-bounce`}
                        >
                          허세지수: {carBagIndex}
                        </div>
                        <div className={`${carBagLevel.color} font-medium`}>
                          {carBagLevel.level}
                        </div>
                        {isRecommended && (
                          <div className="text-green-500 text-sm mt-2 animate-pulse">
                            ✨ 추천 상품
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="max-w-2xl mx-auto">
          <LoginSession />
        </div>
      </div>
    </div>
  );
};
export default QuestionVanity;
